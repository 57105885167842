import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import AulatimeFooter from 'aulatime/src/components/Footer';

import Logo from '../img/logo_h180.png';
import LondonSkyline from './LondonSkyline';

import MdxPrivacy from '../legal/Privacy.mdx';
import MdxTerms from '../legal/Terms.mdx';

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            social {
              facebook
              instagram
            }
          }
        }
      }
    `
  );

  return (
    <AulatimeFooter
      logo={Logo}
      skyline={<LondonSkyline/>}
      mdxPrivacy={<MdxPrivacy/>}
      mdxTerms={<MdxTerms/>}
      siteMetadata={data.site.siteMetadata}
    />
  );
}

export default Footer;
